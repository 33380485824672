module.exports = {
    success: '成功',
    error: '失敗',
    exception: '異常',
    operate: '操作',
    query: '查詢',
    add: '添加',
    edit: '編輯',
    copy: '複製並保存',
    check: '審核',
    recall: '召回',
    rejected: '拒絕',
    withdraw: '撤回',
    delete: '刪除',
    disable: '禁用',
    search: '搜索',
    refresh: '刷新',
    save: '保存',
    submit: '提交',
    return: '返回',
    mainmenu: '主頁面',
    pleaseSelect: '請選擇',
    pleaseEnter: '請輸入',
    home: '首頁',
    details: '詳情',
    successfullyDeleted: '刪除成功',
    menu: '菜單',
    button: '按鈕',
    checkAll: '全選',
    all: '所有',
    //全局彈框設置
    messageBox: {
        titleTips: '提示',
        msgDelete: '此操作將永久刪除，是否繼續？',
        msgDelivered: '此操作將會確認收貨，是否繼續?',
        msgRepayment: '此操作將會確認還款，是否繼續?',
        msgCancel: '此操作將會取消單據，是否繼續?',
        btnConfirm: '確認',
        btnCancel: '取消',
        msgLoginAgain: '密碼修改成功，請重新登錄！',
        msgLoginAgain2: '密碼為初始密碼，請修改！',
        connecterror: '連接超時，請確認網絡是否完好並且重繪一下頁面！'
    },
    //菜單
    vueMenu: {
        crumbMenuManager: '菜單管理',
        labMenuType: '菜單類型',
        labMenuNameZHCN: '菜單名稱(簡體中文)',
        labMenuNameEN: '菜單名稱(English)',
        labMenuNameZHTW: '菜單名稱(繁體中文)',
        labIcon: '圖標',
        labRoutePath: '路由路徑',
        labRouteName: '路由名稱',
        labRouteComponent: '路由組件',
        labRouteParentName: '路由父級名稱',
        labParentMenu: '父級菜單',
        labSort: '排序',
        labDisabled: '禁用',
        labIsDisabled: '是否禁用',
        labNoRequiresAuth: '無需授權',
        labIsData: '是否數據',
        selIsDisabledYes: '是',
        selIsDisabledNo: '否',
        radioMenuTypeSideMenu: '側邊菜單',
        radioMenuTypeButton: '按鈕',
        radioMenuTypeAPI: '接口',
        verifyMenuNameENRequired: '請輸入菜單名稱(English)！',
        verifyMenuNameZHTWRequired: '請輸入菜單名稱(繁體中文)！',
        btnLogout: '註銷',
        btnPassword: '修改密碼',
        btnQuerySubmenu: '查詢子菜單',
        btnAddSubmenu: '添加子菜單',
        msgDeleteSubmenuCount: '刪除失敗，因為有子菜單！'
    },
    //角色
    vueRole: {
        crumbAuthorityManager: '權限管理',
        labRoleName: '角色名稱',
        labIsAdmin: '管理員',
        labCompanys: '權限公司',
        labDepartments: '權限部門',
        labUsers: '適用員工',
        labMenu: '權限菜單/按鈕',
        labMenuAllData: '所有數據',
        labDisabled: '禁用',
        labIsDisabled: '是否禁用',
        selIsDisabledYes: '是',
        selIsDisabledNo: '否',
        verifyRoleNameRequired: '請輸入角色名稱！',
        msgRoleNull: '角色不存在！',
        msgRoleNameExist: '角色名稱已存在！',
    },
    //登錄
    vueLogin: {
        labNumber: '賬號',
        labPassword: '密碼',
        labLanguage: '語言包',
        selLanguage: '請選擇語言',
        verifyNumberRequired: '請輸入賬號！',
        verifyPasswordRequired: '請輸入密碼！',
        verifyNumberPassword: '賬號或密碼錯誤！',
        verifyTokenRequired: '請重新登錄！',
        btnSubmitForm: '登錄',
        btnForgetPassword: '忘記密碼',
        userdisabled: '用戶被禁用！',
        userdoesnotexist: '用戶不存在！',
    },
    //流程
    vueWorkFlow: {
        //模板
        crumbTemplateManager: '流程模板管理',
        labFormType: '單據類型',
        labTemplateName: '模板名稱',
        labCompanys: '公司',
        labDepartments: '部門',
        labUsers: '員工',
        labSort: '排序',
        labDisabled: '禁用',
        labIsDisabled: '是否禁用',
        selIsDisabledYes: '是',
        selIsDisabledNo: '否',
        verifyFormTypeRequired: '請輸入單據類型！',
        verifyTemplateNameRequired: '請輸入模板名稱！',
        verifySortRequired: '請輸入排序！',
        msgAddTemplateNameExist: '模板名稱已存在！',
        msgSetFolwAddBranch: '請添加分支！',
        btnSetFlows: '設置流程',
        //審批流程
        sign0: '待審批',
        sign1: '審批中',
        sign2: '已審批',
        signCC0_1: '未抄送',
        signCC2: '已抄送',
        operates1: '提交單據',
        operates1_1: '重新提交單據',
        operates3: '退回',
        operates4: '拋轉',
        infoTransmit: '拋轉：',
        infoCC: '抄送：',
        infoReturn: '回退：',
        titleWorkflowError: '工作流程錯誤',
        enterNumberName: '請輸入編碼/名稱',
        selMaxGrade: '請選擇最高職等',
        selMaxRank: '請選擇最高職級',
        labApprovalContent: '審批內容',
        labPerson: '員工',
        btnCheck_1: '召回',
        btnCheck_2: '撤回',
        btnCheck2: '通過',
        btnCheck3: '退回上一級',
        btnCheck3_1: '退回申請人',
        btnCheck4: '拋轉',
        btnCheck5: '通過並抄送',
        btnCheck6: '發送電子郵件',
        msgAccountNotExist: '帳戶不存在！',
        msgAccountIsDisabled: '帳戶已禁用！',
        msgTemplateNotExist: '模板不存在！',
        msgEnterApprovalContent: '請輸入審批內容！',
        msgSelectPersonnel: '請選擇員工！',
        msgResponse1: '流程不存在',
        msgResponse2: '無法操作當前流程',
        msgResponse3: '請選擇轉發人',
        msgResponse4: '請選擇抄送人',
        msgResponse5: '員工不存在或者離職',
        msgResponse6: '無法撤消，單據已被他人操作',
        msgResponse7: '無法撤消,單據未提交',
        msgResponse8: '無法回退，當前是第一個審批人',
        msgConfirmRecall: '此操作將召回，是否繼續？',
        msgConfirmWithdraw: '此操作將撤回批準，是否繼續？',
        msgConfirmReturn: '此操作將退回給上級審批人，是否繼續？',
        msgConfirmReturnToApplicant: '此操作蔣退回給申請人，工作流程需要重新開始，是否繼續？',
        divider1: '同時滿足下列條件',
        whereType1: '申請人',
        whereType2: '員工',
        whereType3: '依次上級審批',
        whereType4: '依次部門領導審批',
        whereType1001: '申請人是',
        whereType1002: '申請人不是',
        whereType1003: '申請人部門是',
        whereType1004: '申請人部門不是',
        whereType1005: '申請人公司是',
        whereType1006: '申請人公司不是',
        whereType1007: "申請人上級是",
        whereType1008: "申請人上級不是",
        whereType1009: '申請人的部門領導是',
        whereType1010: '申請人的部門領導不是',
        whereType1011: "申請人的職等是",
        whereType1012: "申請人的職等不是",
        whereType1013: "申請人的職級是",
        whereType1014: "申請人的職級不是",
        nodeApplicant: '申請人',
        nodeApproval: '審批人',
        nodeCondition: '條件',
        nodeConditionBranch: '條件分支',
        nodeAddCondition: '添加條件',
        nodeMergeBranch: '合併分支',
        nodeCC: '抄送',
        nodeEnd: '結束',
    },
    //公司
    vueCompany: {
        plaSearch: "名稱/編號",
        crumbCompanyManager: '公司管理',
        labCompanyName: "公司",
        labCompanyNumber: "公司編號",
        labId: "ID",
        labKeyword: "關鍵字",
        labDisabled: '禁用',
        isFactory: '工廠',
        labIsDisabled: '是否禁用',
        selIsDisabledYes: '是',
        selIsDisabledNo: '否',
        verifyCompanyRequired: '請輸入公司名稱！',
        verifyCompanyNumberRequired: '請輸入公司編號！',
        msgExistCompany: "保存失敗,因為該編碼重復！",
        msgExistDepartment: "禁用失敗，因為該公司存在部門！",
        msgExistEditCompany: "保存失敗,因為該公司存在部門！",
    },
    //部門
    vueDepartment: {
        plaSearch: "名稱/編號",
        crumbDepartmentManager: '部門管理',
        labDepartmentName: "部門名稱",
        labParentDepartmentName: "上級部門",
        labCompanyName: "公司名稱",
        labLeader: '負責人',
        labDepartmentPath: '部門路徑',
        labDepartmentNumber: "部門編號",
        labKeyword: "關鍵字",
        labDisabled: '禁用',
        labIsDisabled: '是否禁用',
        selIsDisabledYes: '是',
        selIsDisabledNo: '否',
        verifyDepartmentRequired: '請輸入部門！',
        verifyCompanyRequired: '請輸入公司！',
        msgEditDepartment: '編輯失敗，因為存在子部門！',
        msgExistDepartment: '保存失敗，因為該部門已經存在！',
        msgExistSubDepartment: "禁用失敗，因為該部門存在子部門！",
        msgExistUser: '禁用失敗,因為存在人員！',
        msgDisabledCompany: "啟用失敗 因為該公司已被禁用！",
        msgDisabledParentDepartment: "啟用失敗 因為上級部門已被禁用"
    },
    //人員
    vueUser: {
        plaSearch: "姓名/工號",
        crumbUserManager: '員工管理',
        labKeyword: "關鍵字",
        labDepartmentName: "部門",
        labCompanyName: "公司",
        labEmail: "郵件",
        //labNumber: "工號",
        labNumber: "工號",
        labLanguage: "語言",
        labRank: "職級",
        labGrade: "職等",
        labLeader: "上級",
        labSex: "性別",
        labAddress: "地址",
        labTel: "電話",
        labAccount: '賬號',
        labName: "姓名",
        labPassword: "密碼",
        labConfirmPassword: "確認新密碼",
        labNewPassword: "新密碼",
        verifyConfirmPassword: '新密碼和確認密碼不一致',

        verifyoldpassword: '舊密碼為空!',
        verifyconfirmpassword: '確認密碼為空!',
        verifypassword: '新密碼為空!',
        verifypassword2: '新密碼長度至少6位!',
        verifypassword3: '新密碼長度不能大於8位!',
        verifyConfirmPassword2: '密碼必須包含數字應為字母特殊符號!',
        verifyConfirmPassword3: '密碼必須包含至少一個大寫字母',

        labOldPassword: '當前密碼',
        labDisabled: '禁用',
        labIsDisabled: '是否禁用',
        whereGrades1: '一職等',
        whereGrades2: '二職等',
        whereGrades3: '三職等',
        whereGrades4: '四職等',
        whereGrades5: '五職等',
        whereGrades6: '六職等',
        whereGrades7: '七職等',
        whereGrades8: '八職等',
        whereGrades9: '九職等',
        whereGrades10: '十職等',
        whereGrades11: '十一職等',
        whereGrades12: '十二職等',
        whereGrades13: '十三職等',
        whereGrades14: '十四職等',
        whereRanks1: '採購及營運主任',
        whereRanks2: '副主任',
        whereRanks3: '會計經理',
        whereRanks4: '總經理',
        whereRanks5: '市場總監',
        whereRanks6: '採購',
        whereRanks7: '主任',
        whereRanks8: '財務經理',
        whereRanks9: '帳戶/採購',
        whereRanks10: '產品業務發展',
        whereRanks11: 'IT 人員',
        whereRanks12: '營運副經理',
        whereRanks13: '業務主管',
        whereRanks14: '採購主管',
        whereRanks15: '採購副經理',
        whereRanks16: 'IT 副經理',
        whereRanks17: '會計主管',
        whereRanks18: '高級財務報告經理',
        whereRanks19: 'IT 主管',
        whereRanks20: '庫存管理員',
        whereRanks21: '銷售經理',
        selIsDisabledYes: '是',
        selIsDisabledNo: '否',
        selMan: '男',
        selWoman: '女',
        editPassword: '修改密碼',
        verifyPasswordRequired: "請輸入密碼！",
        verifyPasswordLen: "密碼長度為6-8位！",
        verifyPasswordLen2: "舊密碼不對！",
        verifyDepartmentRequired: '請輸入部門！',
        verifyCompanyRequired: '請輸入公司！',
        verifyNameRequired: '請輸入姓名！',
        verifyAccountRequired: '請輸入賬號！',
        verifyLanguageRequired: '請選擇語言！',
        verifyEmail: '郵箱格式錯誤！',
        msgExistAccount: '該賬號已經存在',
    },

    //物料類別
    vueCategory: {
        categorypage: '物料類別',
        addcategorypage: '添加物料類別',
        editcategorypage: '編輯物料類別',
        category: '類別',
        category_id: '類別Id',
        category_number: '類別編號',
        button_update: '更新',
        button_search: '查詢',
        button_add: '添加',
        button_delete: '刪除',
        button_sava: '保存',
        addcategoryerror: '類別或者類別編號是空的，不能添加數據.',
        verifyCategoryRequired: '請輸入類別 !',
        verifyCategoryNumberRequired: '請輸入類別編號 !',
        msgExistCategory: "保存失敗,因為該類別重復！",
        msgExistEditCategory: "保存失敗,因為該類別編號重復！"
    },
    //產品
    vueProduct: {
        productpage: '物料檔',
        addproductpage: '添加物料',
        editproductpage: '編輯物料',
        productdetailpage: '物料明細',
        product_id: 'ID',
        product_type: '產品類型',
        product_code: 'EPO產品編號',
        item_code: 'SAP產品編號',
        labProductCode: '產品編號',
        factory_code: '工廠編號',
        product_category: '產品物料類別',
        model_no: '產品型號',
        product_name: '產品名稱',
        product_colour: '產品顏色',
        product_currency: '幣別',
        unit_price: '價格',
        price: 'OIH 價格',
        uom: '單位',
        isDisabled: '禁用',
        button_add: '添加新數據',
        addproducterror: '產品號已經存在，請重新輸入其他的產品編號！',
        verifyProductTypeRequired: '請輸入產品類別 !',
        verifyProductCategoryRequired: '請輸入產品物料類別 !',
        verifyProductColourRequired: '請輸入產品顏色 !',
        verifyProductModelRequired: '請輸入產品型號 !',
        addproducterror2: '產品類別、產品物料類別、產品顏色或者產品型號是空的，不能添加數據.'
    },

    vueProductCol: {
        productcolpage: '物料顏色',
        addproductcolpage: '添加物料顏色',
        editproductcolpage: '編輯物料顏色',
        ProductColourID: '物料顏色Id',
        ProcuctColour: '物料顏色',
        ProductColourNumber: '物料顏色號碼',
        addproductcolerror: '物料顏色或者顏色編號為空，不能新增數據.',
        verifyProductcolRequired: '請輸入物料顏色 !',
        verifyProductcolNumberRequired: '請輸入物料顏色號碼 !',
        msgExistProductCol: "保存失敗,因為該顏色重復！",
        msgExistEditProductCol: "保存失敗,因為該顏色號碼重復！"
    },

    vueProductMod: {
        productmodpage: '物料型號',
        addproductmodpage: '添加型號',
        editproductmodpage: '編輯型號',
        ProductModelID: '型號Id',
        ProcuctModel: '全稱',
        ModelLevel: '等級',
        ModelType: '型號類別',
        DataType: '類別',
        ModelName: '型號名稱',
        remarks: '備註',
        ProductModelNumber: '物料型號號碼',
        //addproductmoderror: '物料型號或者物料型號號碼為空，不能新增數據.',
        addproductmoderror: '型號名稱為空，不能新增數據.',
        verifyProductmodRequired: '請輸入物料型號 !',
        verifyProductmodNumberRequired: '請輸入物料型號號碼 !',
        msgExistProductMod: "保存失敗,因為該型號重復！",
        msgExistEditProductMod: "保存失敗,因為該型號號碼重復！",
        pleaseSelectModelLevel: '請選擇型號等級',
        pleaseSelectModelType: '請選擇型號類型',
    },

    vueVendor: {
        vendor_id: 'ID',
        companyType: '供應商類型',
        vendorpage: '供應商品',
        addvendorpage: '添加',
        vendordetailpage: '供應商明細',
        editvendorpage: '編輯',
        vendor_name: '供應商名稱',
        vendor_number: '供應商編號',
        vendor_address: '地址',
        paymentterm: '付款方式',
        currency: '幣種',
        vendor_contact: '聯繫人',
        vendorContact_finance: '財務聯繫人',
        vendor_tel: '電話',
        vendor_email: '郵箱',
        vendorEmail_p: '財務郵箱',
        country: '城市',
        addvendorerror: '供應商或者供應商編號為空，不能新增數據.',
        verifyVendorRequired: '請輸入數據!',
        msgExistVendorNumber: '供應商編號重復!',
        fullname: '全稱',
        vendor_oihnumber: 'OIH供應商編號'
    },
    vueCurrency: {
        currencypage: '幣種資訊',
        addcurrencypage: '新增幣種',
        editcurrencypage: '編輯幣種',
        remarks: '備註',
        currencyid: 'ID',
        currency: '幣種',
        currencyNumber: '幣種編號',
        addproductcolerror: '幣種或者幣種編號為空，不能新增數據.',
        verifyCurrencyRequired: '請輸入幣種 !',
        verifyCurrencyNumberRequired: '請輸入幣種編號 !',
        msgExistCurrency: "保存失敗，因為幣種重複!",
        msgExistEditCurrency: "保存失敗，因為幣種編號重複!"
    },

    vueMailRecord: {
        mailrecordpage: '郵件記錄',
        mailrecordPO: 'PO單號',
        mailsendtime: '發送時間',
        mailSender: '發件人',
        mailPONumber: 'EPO PO單號',
        mailSAPT9Number: 'SAP/T9 單號',
        mailContent: '結果',
        mailaddressee: '收件人',
        btnresend: '重新發送',
        btnPDF: 'PDF',
    },


    //定時任務
    vueTaskOptions: {
        taskOptionspage: '定時任務',
        taskOptions_id: '任務Id',
        labtaskName: '任務名稱',
        labGroupName: '分組',
        labLastRunTime: '最後執行時間',
        labStatus: '狀態',
        labInterval: '間隔(Cron)',
        labDescribe: '描述',
        labApiUrl: 'ApiUrl',
        labRequestType: '請求方式',
        addTaskOptionspage: '新增任務',
        editTaskOptionsPage: '編輯任務',
        labAuthKey: '請求頭部(Key)',
        labAuthValue: '請求頭部(Value)',
        button_sava: '提交',
        verifyTaskOptionstaskNameRequired: '請輸入任務名稱',
        verifyTaskOptionsGroupNameRequired: '請輸入分組',
        verifyTaskOptionsIntervalRequired: '請輸入間隔時間',
        verifyTaskOptionsApiUrlRequired: '請輸入ApiUrl',
        verifyTaskOptionsRequestTypeRequired: '請選擇請求方式',
        button_ExecuteNow: '立即執行',
        button_Pause: '暫停',
        button_Start: '開啓',
        button_log: '任務日誌',
        button_update: '編輯',

        JobActionType: '操作類型',
        TaskOptions: '定時任務',
        RunTime: '運行時間',
        Status: '狀態',
        taskLogspage: '任務日誌',
        taskOptionsAddError: '定時運算式錯誤！',
        taskOptionsAddError2: '任務已存在！',
    },
    vueSales: {
        SalesTarget: '銷售目標管理',
        SalesList: '銷售目標管理',
        SalesData: '銷售數據管理',
        SalesDataList: '銷售數據上傳',
        country: '國家',
        rule: '指標',
        product: '產品',
        salesTarget: '銷售目標查詢',
        year: '年度',
        month: '月度',
        date: '日',
        addTime: '創建日期',
        addUserNumber: '創建人員',
        indexNo: '序號',
        isDisabled: '是否失效',
        btn_query: '查詢',
        btn_excel: '導出',
        btn_add: '添加新銷售目標',
        btn_add_salesDate: '添加新銷售數據',
        amount: '銷售額',
        quantity: '銷量',
        byAmount: '銷售額',
        byQuantity: '銷量',
        yearisnotnull: '年度不能爲空',
        monthisnotnull: '月度不能爲空',
        ruleisnotnull: '指標不能爲空',
        SalesTargetDetails: '銷售目標明細',
        IsDisabledYes: '失效',
        IsDisabledNo: '生效',
        btnAddForm: '增加行',
        operate: '操作',
        verifySalesTargetRequired: '必輸項',
        targetExists: '銷售目標已存在或已失效。',
        noTarget: '這個時間未維護銷售目標。',
        hasTarget: '此年月的銷售目標已維護，請使用修改功能維護。',
        zeroMessage: '明細含有數量或金額為0.',
        datePeriod: '周期',
        sumQuantity: '縂銷售數量',
        sumAmount: '縂銷售金額',
        uploadQuantity: '銷售數量',
        uploadAmount: '銷售金額',
        hasSalesData: '此周期的銷售數據已維護，請使用修改功能維護。',
        buttonrelease: '發佈',

        statusLabel: '狀態',
        releaseStatus0: '草稿',
        releaseStatus1: '發佈',
        weeksalesdatapage: '周銷售數據',
        addweeksalesdatbtn: '新增周銷售數據',
        weekAmount: '周金額',
        editWeekSalesData: '編輯周銷售數據',

        OIHTAmount: 'OIH銷售目標',
        OIHCAmount: 'OIH公司目標',
        OIHTargetAmount: 'OIH銷售目標',
        OIHCorporateAmount: 'OIH公司目標',
        NonAmountTarget: '缺少銷售金額目標未維護，不能發佈，請先維護好銷售金額目標。',
        NonQuantyTarget: '缺少銷售數量目標未維護，不能發佈，請先維護好銷售數量目標。',
    },
    vueSDPReport: {
        categoryLabel: '類別',
        modelLabel: '型號',
        modellevelLabel: '型號等級',
        modeltypeLabel: '類型',

        totalLabel: '匯總',

        excelButton: '導出Excel',

        dateLabel: '年-月',
        pleaseSelectModelType: '請選擇等級',
        pleaseSelectModelLevel: '請選擇類型',

        targetQuantityLabel: '目標數量',
        chajuLabel: '數量差',
        fieldName: '日常銷售目標',
        SalesDataReportPage: '銷售數據報表',
        SalesTargetAnalysisReport: '銷售目標分析報表',
        SalesTargetTotalReportPage: '銷售目標匯總分析報表',

        //corporateLabel: 'Corporate',
        corporateLabel: '線上',
        offlineLabel: '零售數據',

        //totalCorporateData: 'TotalCorporate',
        totalCorporateData: '匯總線上數據',
        totalOfflineData: '匯總零售數據',
        totalDataLebel: '匯總',

        modayDataLebel: '週一數據',
        dailyDataLebel: '每天數據',
    },
    vueExchangeRate: {
        ExchangeRateListPage: '匯率數據',
        oriCurrencyLabel: '原幣',
        ChangeRateLabel: '匯率',
        locCurrencyLabel: '本幣',
        dataduplication: '數據重複',
    },

    vueControllerMessage: {
        companyexistMsg: '公司不存在 !',
        disabledSuccessfully: '禁用成功 !',
        successfullyEnabled: '啟用成功 !',
        departmentexistMsg: '部門不存在 !',
        personexistMsg: '用戶不存在 !',
        presencesubmenu: '存在子菜單!',
        menudoesnotexist: '目錄不存在!',
        processdoesnotexist: '流程範本不存在!',
        vendordoesnotexist: '供應商不存在!',
        productdoesnotexist: '產品不存在!',
        categorydoesnotexist: '類別不存在!',
        modeldoesnotexist: '型號不存在!',
        colourdoesnotexist: '顏色不存在!',
        purchasedoesnotexist: '核價資訊不存在!',
        currencydoesnotexist: '幣種資訊不存在!',
        filedoesnotexist: '檔不存在!',
        podoesnotexist: '訂單不存在!',
        poshipmentdoesnotexist: '送貨資訊不存在!',
    },
}