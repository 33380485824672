<template>
    <div id="msgbox">
        <el-dialog v-model="dialogTableVisible"
                   width="100%" @opened="openDialog">
            <el-form ref="productModelForm" :model="productModelForm" :inline="true" class="demo-form-inline">
                <el-form-item :label="$t('message.vueProductMod.ProcuctModel')+' :'">
                    <el-input size="mini" v-model="productModelForm.modelNo"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" id="btn_search" @click="submitSearch" icon="el-icon-search">{{$t('message.search')}}</el-button>
                    <el-button type="primary" id="btn_confirm" @click="confirm">{{$t('message.messageBox.btnConfirm')}}</el-button>
                </el-form-item>
            </el-form>
            <el-table ref="multipleTable"
                      :data="tableData"
                      tooltip-effect="dark"
                      style="width: 100%"
                      size="mini"
                      @selection-change="handleSelectionChange" border>
                <el-table-column type="selection"
                                 width="45">
                </el-table-column>
                <!--<el-table-column prop="ModelName" :label="$t('message.vueProductMod.ModelName')" width="170">
        <template #default="scope">
            <span>{{scope.row.modelName}}</span>
        </template>
    </el-table-column>
    <el-table-column prop="ModelType" :label="$t('message.vueProductMod.ModelType')" width="140">
        <template #default="scope">
            <span>{{scope.row.modelType}}</span>
        </template>
    </el-table-column>-->
                <el-table-column prop="ProcuctModel" :label="$t('message.vueProductMod.ProcuctModel')" width="340">
                    <template #default="scope">
                        <span>{{scope.row.productMod}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="ModelLevel" :label="$t('message.vueProductMod.ModelLevel')" width="80">
                    <template #default="scope">
                        <span>{{scope.row.modelLevel}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="DataType" :label="$t('message.vueProductMod.DataType')" width="80">
                    <template #default="scope">
                        <span>{{scope.row.dataType}}</span>
                    </template>
                </el-table-column>

                <!--<el-table-column prop="Remarks" :label="$t('message.vueProductMod.remarks')" width="150">
        <template #default="scope">
            <span>{{scope.row.remarks}}</span>
        </template>
    </el-table-column>

    <el-table-column prop="ProductModNa" :label="$t('message.vueProductMod.ProductModelNumber')" width="120">
        <template #default="scope">
            <span>{{scope.row.productModNa}}</span>
        </template>
    </el-table-column>-->
            </el-table>
                <div class="block">
                    <el-pagination @size-change="handleSizeChange"
                                   @current-change="handleCurrentChange"
                                   :current-page="pageIndex"
                                   :page-sizes="[10, 25, 50, 75, 100]"
                                   :page-size="pageSize"
                                   layout="total, sizes, prev, pager, next, jumper"
                                   :total="count">
                    </el-pagination>
                </div>
</el-dialog>
        </div>
    </template>
    <script>
        import AppStorage from '@/common/storage/app-storage'
        export default {
            name: '',
            data() {
                return {
                    tableData: null,
                    language: AppStorage.getLanguage(),
                    multipleSelection: [],
                    productModelForm: {
                        modelNo:''
                    },
                    pageIndex: 1,
                    pageSize: 10,
                    count: 0,
                    dialogTableVisible: false,
                }
            },
            methods: {
                loadPage() {
                    this.axios
                        .get('/api/ProductMod/getproductmodpage', {
                            params: {
                                isDisabled: 'false',
                                ProductMod: this.productModelForm.modelNo,
                                pageIndex: this.pageIndex,
                                pageSize: this.pageSize
                            }
                        }) .then(response => {
                            if (response.data.code == 1) {
                                var infos = response.data.data;
                                this.tableData = infos.items;
                                this.count = infos.count;
                            }
                        })
                },
                submitSearch() {
                    this.pageIndex = 1;
                    this.loadPage();
                },
                handleSizeChange(val) {
                    this.pageSize = val;
                    this.loadPage();
                },
                handleCurrentChange(val) {
                    this.pageIndex = val;
                    this.loadPage();
                },
                handleSelectionChange(val) {
                    this.multipleSelection = val;
                },
                init() {
                    this.dialogTableVisible = true;
                },
                confirm() {
                    console.log(this.multipleSelection);
                    this.$emit("getProductsEven", this.multipleSelection);
                    this.dialogTableVisible = false;
                },
                openDialog() {
                    this.$refs.multipleTable.clearSelection();
                }
            },
            mounted() {
                this.submitSearch();
            },
            watch: {
                $route() {
                    //响应路由参数的变化
                    this.submitSearch();
                }
            }

        };
    </script>
