module.exports = {
    success: 'Success',
    error: 'Error',
    exception: 'Exception',
    operate: 'Operate',
    //query: 'Query',
    query: 'Search',
    add: 'Add',
    edit: 'Edit',
    copy: 'Copy And Save',
    delete: 'Delete',
    disable: 'Disable',
    search: 'Search',
    refresh: 'Refresh',
    check: 'Approval',
    recall: 'Recall',
    rejected:'Rejected',
    withdraw: 'Recall',
    save:'Save',
    submit: 'Submit',
    return: 'Return',
    mainmenu: 'Main Menu',
    pleaseSelect: 'Please select',
    pleaseEnter: 'Please enter',
    home: 'Home',
    details: 'Details',
    successfullyDeleted: 'Delete succeeded',
    menu: 'Menu',
    button: 'Button',
    checkAll: 'Check All',
    all: 'All',
    //全局弹框设置
    messageBox: {
        titleTips: 'Tips',
        msgDelete: 'This operation will be permanently deleted. Do you want to continue ?',
        msgDelivered: 'This operation will confirm receipt. Do you want to continue ?',
        msgRepayment: 'This operation will confirm the repayment. Do you want to continue ?',
        msgCancel: 'This operation will cancel the PO. Do you want to continue ?',
        btnConfirm: 'Confirm',
        btnCancel: 'Cancel',
        msgLoginAgain: 'Password changed successfully, please login again!',
        msgLoginAgain2: 'The password is the initial password, please modify it!',
        connecterror: 'Connection timeout, please confirm whether the network is intact and refresh the page!'
    },
    //菜单
    vueMenu: {
        crumbMenuManager: 'Menu Manager',
        labMenuType: 'Menu type',
        labMenuNameZHCN: 'Menu Name(简体中文)',
        labMenuNameEN: 'Menu Name(English)',
        labMenuNameZHTW: 'Menu Name(繁體中文)',
        labIcon: 'Icon',
        labRoutePath: 'Route Path',
        labRouteName: 'Route Name',
        labRouteComponent: 'Route Component',
        labRouteParentName: 'Route Parent Name',
        labParentMenu: 'Parent Menu',
        labSort: 'Sort',
        labDisabled: 'Disabled',
        labIsDisabled: 'Is it disabled',
        labNoRequiresAuth: 'No Requires Auth',
        labIsData: 'Data',
        selIsDisabledYes: 'Yes',
        selIsDisabledNo: 'No',
        radioMenuTypeSideMenu: 'SideMenu',
        radioMenuTypeButton: 'Button',
        radioMenuTypeAPI: 'API',
        verifyMenuNameENRequired: 'Please enter the Menu Name(English) !',
        verifyMenuNameZHTWRequired: 'Please enter the Menu Name(繁體中文) !',
        btnLogout: 'Log out',
        btnPassword: 'Change Password',
        btnQuerySubmenu: 'Query Submenu',
        btnAddSubmenu: 'Add Submenu',
        msgDeleteSubmenuCount: 'Delete failed because of submenu !'
    },
    //角色
    vueRole: {
        crumbAuthorityManager: 'Authority Manager',
        labRoleName: 'Role Name',
        labIsAdmin: 'Administrator',
        labCompanys: 'Authority Company',
        labDepartments: 'Authority Department',
        labUsers: 'Users',
        labMenu: 'Authority Menu/Button',
        labMenuAllData: 'All data',
        labDisabled: 'Disabled',
        labIsDisabled: 'Is it disabled',
        selIsDisabledYes: 'Yes',
        selIsDisabledNo: 'No',
        verifyRoleNameRequired: 'Please enter the role name !',
        msgRoleNull: 'The role does not exist !',
        msgRoleNameNull: 'The role does not exist !',
        msgRoleNameExist: 'The role name already exists !',
    },
    //登录
    vueLogin: {
        labNumber: 'Account',
        labPassword: 'Password',
        labLanguage: 'Language',
        selLanguage: 'Please select language',
        verifyNumberRequired: 'Please enter the account number !',
        verifyPasswordRequired: 'Please enter the password !',
        verifyNumberPassword: 'Wrong account or password !',
        verifyTokenRequired: 'Please login again !',
        userdisabled: 'User disabled !',
        userdoesnotexist: 'User does not exist !',
        btnSubmitForm: 'Sign in',
        btnForgetPassword: 'Forget Password'
    },
    //流程
    vueWorkFlow: {
        //模板
        crumbTemplateManager: 'WorkFlow Template Manager',
        labFormType: 'Bill Type',
        labTemplateName: 'Template Name',
        labCompanys: 'Companys',
        labDepartments: 'Departments',
        labUsers: 'Users',
        labSort: 'Sort',
        labDisabled: 'Disabled',
        labIsDisabled: 'Is it disabled',
        selIsDisabledYes: 'Yes',
        selIsDisabledNo: 'No',
        verifyFormTypeRequired: 'Please enter the bill type !',
        verifyTemplateNameRequired: 'Please enter the template name !',
        verifySortRequired: 'Please enter the sort !',
        msgAddTemplateNameExist: 'The template name already exists !',
        msgSetFolwAddBranch: 'Please add branch !',
        btnSetFlows: 'Set up workflow',
        //审批流程
        sign0: 'Pending Approval',
        sign1: 'In Approval',
        sign2: 'Approved',
        signCC0_1: 'No CC',
        signCC2: 'Has been CC',
        operates1: 'Submit',
        operates1_1: 'Re Submit',
        operates3: 'Rejected',
        operates4: 'Forward',
        infoTransmit: 'Forward:',
        infoCC: 'CC:',
        infoReturn: 'Rejected:',
        titleWorkflowError: 'Workflow Error',
        enterNumberName: 'Please enter the number/name',
        selMaxGrade: 'Choose the highest grade',
        selMaxRank: 'Choose the highest rank',
        labApprovalContent: 'Approval Content',
        labPerson: 'Person',
        btnCheck_1: 'Recall',
        btnCheck_2: 'Recall',
        btnCheck2: 'Submit Approval',
        btnCheck3: 'Return to previous',
        btnCheck3_1: 'Return to creator',
        btnCheck4: 'Forward',
        btnCheck5: 'Pass and CC',
        btnCheck6: 'Submit Send Email',
        msgAccountNotExist: 'Account does not exist !',
        msgAccountIsDisabled: 'Account Is Disabled !',
        msgTemplateNotExist: 'Template does not exist !',
        msgEnterApprovalContent: 'Please enter the approval content !',
        msgSelectPersonnel: 'Please select the personnel !',
        msgResponse1: 'Workflow does not exist',
        msgResponse2: 'The current workflow cannot be operated',
        msgResponse3: 'Please select forwarding person',
        msgResponse4: 'Please select CC person',
        msgResponse5: 'Non existence or resignation of personnel',
        msgResponse6: 'Cannot withdraw. The form has been operated by others.',
        msgResponse7: 'Cannot withdraw. Form not submitted.',
        msgResponse8: 'Cannot rejected. Currently the first approver.',
        msgConfirmRecall: 'This operation will recall. Do you want to continue ?',
        msgConfirmWithdraw: 'This operation will revoke the approval. Do you want to continue ?',
        msgConfirmReturn: 'This operation will be returned to the superior approver. Do you want to continue ?',
        msgConfirmReturnToApplicant: 'This operation will be returned to the applicant. The workflow needs to be restarted. Do you want to continue?',
        divider1: 'The following conditions are met simultaneously',
        whereType1: 'Applicant',
        whereType2: 'Person',
        whereType3: 'Superior',
        whereType4: 'Department leaders',
        whereType1001: 'The applicant is',
        whereType1002: 'The applicant is not',
        whereType1003: 'The application department is',
        whereType1004: 'The application department is not',
        whereType1005: 'The applicant company is',
        whereType1006: 'The applicant company is not',
        whereType1007: "The applicant's superior is",
        whereType1008: "The applicant's superior is not",
        whereType1009: 'The head of the application department is',
        whereType1010: 'The head of the application department is  not',
        whereType1011: "The applicant's grade is",
        whereType1012: "The applicant's grade is not",
        whereType1013: "The applicant's rank is",
        whereType1014: "The applicant's rank is not",
        nodeApplicant: 'Applicant',
        nodeApproval: 'Approver',
        nodeCondition: 'Condition',
        nodeConditionBranch: 'Condition Branch',
        nodeAddCondition: 'Add Condition',
        nodeMergeBranch: 'Merge Branch',
        nodeCC: 'CC',
        nodeEnd: 'End',
    },
    //公司
    vueCompany: {
        plaSearch: "Name/Number",
        crumbCompanyManager: 'Company',
        labCompanyName: "Company/Organization",
        labCompanyNumber: "CompanyCode",
        labId: "ID",
        labKeyword: "Keyword",
        verifyCompanyRequired: 'Please enter the company !',
        verifyCompanyNumberRequired: 'Please enter the companynumber !',
        msgExistCompany: "Save failed because of the CompanyNumber repeat!",
        msgExistDepartment: "Disable failed because there are departments in the company!",
        msgExistEditCompany: "Save failed because there are departments in the company!",
        labDisabled: 'Disabled',
        labIsDisabled: 'Is it disabled',
        isFactory: 'Factory',
        selIsDisabledYes: 'Yes',
        selIsDisabledNo: 'No',
    },
    //部门
    vueDepartment: {
        plaSearch: "Name/Number",
        crumbDepartmentManager: 'Department',
        labDepartmentName: "Department",
        labParentDepartmentName: "ParentDepartment",
        labCompanyName: "Company",
        labLeader: 'Leader',
        labDepartmentPath: 'DepartmentPath',
        labDepartmentNumber: "DepartmentNumber",
        labKeyword: "Keyword",
        labDisabled: 'Disabled',
        labIsDisabled: 'Is it disabled',
        selIsDisabledYes: 'Yes',
        selIsDisabledNo: 'No',
        verifyDepartmentRequired: 'Please enter the Department !',
        verifyCompanyRequired: 'Please enter the Company!',
        msgEditDepartment: 'Edit failed because of subDepartment !',
        msgExistDepartment: 'Save failed because of the Department already exists!',
        msgExistUser: 'Disable failed because there are user exists!',
        msgExistSubDepartment: "Disable failed because there are subdepartments in the departments!",
        msgDisabledCompany: "Activation failed because the company has been disabled!",
        msgDisabledParentDepartment:"Activation failed because the superior department has been disabled"
    },
    //用户 
    vueUser: {
        plaSearch: 'Name/Number',
        crumbUserManager: 'User',
        labKeyword: 'Keyword',
        labDepartmentName: 'Department',
        labCompanyName: 'Company',
        labEmail: 'Email',
        labNumber: 'Account Code',
        labAccount:'Account',
        labLanguage: 'Language',
        labRank: 'Title',
        labGrade: 'Grade',
        labLeader: 'Leader',
        labSex: 'Sex',
        labAddress: 'Address',
        labTel: 'Telephone',
        labName: 'Name',
        labPassword: 'Password',
        labConfirmPassword: 'Confirm New Password',
        labNewPassword: 'New Password',

        verifyConfirmPassword: 'New Password and Confirm New Password are inconsistent!',
        verifyoldpassword: 'The original password entered is empty!',
        verifyconfirmpassword: 'Confirm password is empty!',
        verifypassword: 'New password is empty!',
        verifypassword2: 'The length of the new password cannot less than 6 digits!',
        verifypassword3: 'The length of the new password cannot greater than 8 digits!',
        verifyConfirmPassword2: 'The password must consist of numbers, english letters!',
        verifyConfirmPassword3: 'The password must at least one capital letter!',

        labOldPassword: 'Current password',
        labDisabled: 'Disabled',
        labIsDisabled: 'Is it disabled',
        selIsDisabledYes: 'Yes',
        selIsDisabledNo: 'No',
        whereGrades1: 'FirstGrades',
        whereGrades2: 'SecondGrades',
        whereGrades3: 'ThreeGrades',
        whereGrades4: 'FourGrades',
        whereGrades5: 'FiveGrades',
        whereGrades6: 'SixGrades',
        whereGrades7: 'SevenGrades',
        whereGrades8: 'EightGrades',
        whereGrades9: 'NineGrades',
        whereGrades10: 'TenGrades',
        whereGrades11: 'ElevenGrades',
        whereGrades12: 'TwelveGrades',
        whereGrades13: 'ThirteenGrades',
        whereGrades14: 'FourteenGrades',
        whereRanks1: 'Merchandising and Operations Officer',
        whereRanks2: 'Deputy Director',
        whereRanks3: 'Accounting Manager',
        whereRanks4: 'General Manager',
        whereRanks5: 'Marketing Director',
        whereRanks6: 'Purchasing',
        whereRanks7: 'Director',
        whereRanks8: 'Finance Manager',
        whereRanks9: 'Accounts/Purchasing',
        whereRanks10: 'Product Business Development',
        whereRanks11: 'IT Support',
        whereRanks12: 'Assistant Operations Manager',
        whereRanks13: 'Business Director',
        whereRanks14: 'Procurement Executive',
        whereRanks15: 'Assistant Purchasing Manager',
        whereRanks16: 'IT Assistant Manager',
        whereRanks17: 'Accounts Executive',
        whereRanks18: 'Senior Financial Reporting Manager',
        whereRanks19: 'IT Director',
        whereRanks20: 'Inventory Controller',
        whereRanks21: 'Markting Manager',
        selMan: 'Man',
        selWoman: 'Woman',
        editPassword: 'Edit Password',
        verifyPasswordRequired: 'Please enter the Password!',
        verifyPasswordLen: 'Password length should be 6-8 bits!',
        verifyPasswordLen2: 'The Old Password is error!',
        verifyDepartmentRequired: 'Please enter the Department !',
        verifyCompanyRequired: 'Please enter the Company!',
        verifyNameRequired: 'Please enter the Name!',
        verifyAccountRequired: 'Please enter the Account!',
        verifyLanguageRequired: 'Please enter the Language!',
        verifyEmail: 'Email format error!',
        msgExistAccount:'This account already exists'
    },
    //物料类别
    vueCategory: {
        categorypage: 'Product Category',
        addcategorypage: 'Add Category',
        editcategorypage: 'Edit Category',
        category: 'Category',
        category_id: 'ID',
        category_number: 'Category Code',
        button_update: 'Edit',
        button_search: 'Search',
        button_add: 'Add',
        button_delete: 'Delete',
        button_sava: 'Save',
        deleteerror: 'Category is exist in Product,can not delete!',
        addcategoryerror: 'Category or CategoryNumber is null,can not add data.',
        verifyCategoryRequired: 'Please enter the category !',
        verifyCategoryNumberRequired: 'Please enter the categorynumber !',
        msgExistCategory: "Save failed because of the Category repeat!",
        msgExistEditCategory: "Save failed because of the CategoryNumber repeat!"
    },
    //产品
    vueProduct: {
        productpage: 'Product Information',
        addproductpage: 'Add Product',
        editproductpage: 'Edit Product',
        productdetailpage: 'Product Detail',
        product_id: 'ID',
        product_type: 'Product Type',
        product_code: 'EPO Item Code',
        item_code: 'SAP Item Code',
        labProductCode: 'Item Code',
        factory_code: 'Factory Code',
        product_category: 'Product Category',
        model_no: 'Model No',
        product_name: 'Product Description',
        product_colour: 'Product Colour',
        unit_price: 'Unit Price',
        product_currency: 'Currency',
        price: 'OIH Price',
        uom: 'UoM',
        isDisabled: 'IsDisabled',
        button_add:'Create New',
        addproducterror: 'ProductCode is exist,please input again other ProductCode!',
        verifyProductTypeRequired: 'Please enter the Product Type !',
        verifyProductCategoryRequired: 'Please enter the Product Category !',
        verifyProductColourRequired: 'Please enter the Product Colour !',
        verifyProductModelRequired: 'Please enter the Product Model !',
        addproducterror2: 'Product Type,Product Category,Product Colour or Product Model is null,can not add data.'
    },

    vueProductCol: {
        productcolpage: 'Product Color',
        addproductcolpage: 'Add Color',
        editproductcolpage: 'Edit Color',
        ProductColourID: 'ID',
        ProcuctColour: 'Product Colour',
        ProductColourNumber: 'Colour Number',
        deleteerror: 'Colour is exist in Product,can not delete!',
        addproductcolerror: 'Product colour or Product colour number is null,can not add data.',
        verifyProductcolRequired: 'Please enter the product colour !',
        verifyProductcolNumberRequired: 'Please enter the product colour number !',
        msgExistProductCol: "Save failed because of the Product Colour repeat!",
        msgExistEditProductCol: "Save failed because of the Product Colour Number repeat!"
    },
    vueCurrency: {
        currencypage: 'Currency',
        addcurrencypage: 'Add Currency',
        editcurrencypage: 'Edit Currency',
        currencyid: 'ID',
        remarks: 'Remarks',
        currency: 'Currency',
        currencyNumber: 'Currency Number',
        addproductcolerror: 'Currency or Currency number is null,can not add data.',
        verifyCurrencyRequired: 'Please enter the Currency !',
        verifyCurrencyNumberRequired: 'Please enter the Currency number !',
        msgExistCurrency: "Save failed because of the Currency repeat!",
        msgExistEditCurrency: "Save failed because of the Currency Number repeat!"
    },
    vueProductMod: {
        productmodpage: 'Product Model',
        addproductmodpage: 'Add Model',
        editproductmodpage: 'Edit Model',
        ProductModelID: 'ID',
        ProcuctModel: 'Full Name',
        ModelLevel: 'Level',
        ModelType: 'Model Type',
        DataType: 'Type',
        ModelName: 'Model Name',
        remarks: 'Remarks',
        ProductModelNumber: 'Model Number',
        deleteerror: 'Model is exist in Product,can not delete!',
        //addproductmoderror: 'Product model or Product model number is null,can not add data.',
        addproductmoderror: 'Model Name is null,can not add data.',
        verifyProductmodRequired: 'Please enter the product model !',
        verifyProductmodNumberRequired: 'Please enter the product model number !',
        msgExistProductMod: "Save failed because of the Product Model repeat!",
        msgExistEditProductMod: "Save failed because of the Product Model Number repeat!",
        pleaseSelectModelLevel: 'Please Select ModelLevel',
        pleaseSelectModelType: 'Please Select ModelType',
    },

    vueVendor: {
        vendor_id: 'ID',
        companyType: 'Company Type',
        vendorpage: 'Vendor Information',
        vendordetailpage: 'Vendor Detail Information',
        addvendorpage: 'Add Vendor',
        editvendorpage: 'Edit Vendor',
        vendor_name: 'Vendor Name',
        vendor_number: 'Vendor Number',
        vendor_address: 'Vendor Address',
        paymentterm: 'Payment Term',
        currency: 'Currency',
        vendorContact_finance: 'Vendor Contact Finance',
        vendorEmail_p: 'Vendor Email Purchasing',
        country: 'Country',
        vendor_contact: 'Vendor Contact Purchasing',
        vendor_tel: 'Vendor Tel',
        vendor_email: 'Vendor Email Finance',
        addvendorerror: 'Vendor or Vendor number is null,can not add data.',
        verifyVendorRequired: 'Please enter the data !',
        msgExistVendorNumber: 'Vendor number is exist!',
        fullname: 'Full Name',
        vendor_oihnumber: 'Vendor OIH Number'
    },

    vueMailRecord: {
        mailrecordpage: 'Mail Record',
        mailrecordPO: 'PO Number',
        mailsendtime: 'Sent Time',
        mailSender: 'Sender',
        mailPONumber: 'EPO PO Number',
        mailSAPT9Number: 'SAP/T9 Number',
        mailContent: 'Result',
        mailaddressee: 'Addressee',
        btnresend: 'Resend',
        btnPDF: 'PDF',
    },
   
    //定时任务
    vueTaskOptions: {
        taskOptionspage: 'Task Options',
        taskOptions_id: 'Task Id',
        labtaskName: 'Task Name',
        labGroupName: 'Group Name',
        labLastRunTime: 'Last Run Time',
        labStatus: 'Status',
        labInterval: 'Interval(Cron)',
        labDescribe: 'Describe',
        labApiUrl: 'ApiUrl',
        labRequestType: 'Request Type',
        addTaskOptionspage: 'Add Task Options',
        editTaskOptionsPage: 'Edit Task Options',
        labAuthKey: 'Header(Key)',
        labAuthValue: 'Header(Value)',
        button_sava: 'Submit',
        verifyTaskOptionstaskNameRequired: 'Please input task name',
        verifyTaskOptionsGroupNameRequired: 'Please input group name',
        verifyTaskOptionsIntervalRequired: 'Please input interval',
        verifyTaskOptionsApiUrlRequired: 'Please input api url',
        verifyTaskOptionsRequestTypeRequired: 'Please select request type',
        button_ExecuteNow: 'Execute Now',
        button_Pause: 'Pause',
        button_Start: 'Start',
        button_log: 'Task Log',
        button_update: 'Edit',

        JobActionType: 'Action Type',
        TaskOptions: 'Task Options',
        RunTime: 'Run Time',
        Status: 'Status',
        taskLogspage: 'Task Logs',
        taskOptionsAddError: 'Interval(Cron) expression error!',
        taskOptionsAddError2: 'Task already exists!',
    },
    vueSales: {
        SalesTarget: 'Sales Target',
        SalesList: 'Sales Target list',
        SalesData: 'Sales Data',
        SalesDataList: 'Sales Data List',
        country: 'Country',
        rule: 'Rule',
        product: 'Product',
        salesTarget: 'Sales target',
        year: 'Year',
        month: 'Month',
        date: 'Date',
        addTime: 'Add Time',
        addUserNumber: 'Add User',
        indexNo: 'No.',
        isDisabled: 'Disable',
       //btn_query: 'Query',
        btn_query: 'Search',
        btn_excel: 'Excel',
        btn_add: 'Add Sales Target',
        btn_add_salesDate: 'add Sales Data',
        amount: 'Amount',
        quantity: 'Quantity',
        byAmount: 'Amount',
        byQuantity: 'Quantity',
        yearisnotnull: 'Year is not null',
        monthisnotnull: 'Month is not null',
        ruleisnotnull: 'Rule is not null',
        SalesTargetDetails: 'Sales Target Details',
        IsDisabledYes: 'Is Disabled Yes',
        IsDisabledNo: 'Is Disabled No',
        btnAddForm: 'Add Row',
        operate: 'Operate',
        verifySalesTargetRequired: 'Required Field',
        targetExists: 'The sales target already exists or is invalid',
        noTarget: 'No sales target for this time',
        hasTarget: 'The sales target of this month has been maintained. Please use the modification function to maintain it.',
        zeroMessage: 'Details contain quantity or the amount is zero.',
        datePeriod: 'Date Period',
        sumQuantity: 'Sum Quantity',
        sumAmount: 'Sum Amount',
        uploadQuantity: 'Quantity',
        uploadAmount: 'Amount',
        hasSalesData: 'The sales data of this cycle has been maintained. Please use the Modify function to maintain it.',
        buttonrelease: 'Release',

        statusLabel: 'Status',
        releaseStatus0: 'Draft',
        releaseStatus1: 'Released',
        weeksalesdatapage: 'Week Sales Data',
        addweeksalesdatbtn: 'Add Week Sales Data',
        weekAmount: 'Week Amount',
        editWeekSalesData: 'Edit Week Sales Data',

        OIHTAmount: 'OIH T-Amount',
        OIHCAmount: 'OIH C-Amount',
        OIHTargetAmount: 'OIH Target Amount',
        OIHCorporateAmount: 'OIH Corporate Amount',
        NonAmountTarget: 'The sales amount target is not maintained and cannot be released. Please maintain the sales amount target first.',
        NonQuantyTarget: 'The sales quantity target is not maintained and cannot be released. Please maintain the sales quantity target first.',
    },
    vueSDPReport: {
        categoryLabel: 'Category',
        modelLabel: 'Model',
        modellevelLabel: 'Model Level',
        modeltypeLabel: 'Model Type',

        totalLabel: 'Total',

        excelButton: 'Export Excel',

        dateLabel: 'Year-Month',
        pleaseSelectModelType: 'Please select model type',
        pleaseSelectModelLevel: 'Please select model level',

        targetQuantityLabel: 'Target Quantity',
        chajuLabel: 'Quantity Gap',
        fieldName: 'Daily Sales Target',
        SalesDataReportPage: 'Sales Data Report',
        SalesTargetAnalysisReport: ' Sales Target Analysis',
        SalesTargetTotalReportPage: 'Sales Target Summary Analysis',

        //corporateLabel: 'Corporate',
        corporateLabel: 'OnLine',
        offlineLabel: 'RetailData',

        //totalCorporateData: 'TotalCorporate',
        totalCorporateData: 'TotalOnLine',
        totalOfflineData: 'TotalRetailData',
        totalDataLebel: 'Total',

        modayDataLebel: 'Monday Data',
        dailyDataLebel: 'Daily Data',
    },
    vueExchangeRate: {
        ExchangeRateListPage: 'Exchange Rate List',
        oriCurrencyLabel: 'Ori Currency',
        ChangeRateLabel: 'Change Rate',
        locCurrencyLabel: 'Loc Currency',
        dataduplication: 'Data duplication',
    },

    vueControllerMessage: {
        companyexistMsg: 'Company does not exist !',
        disabledSuccessfully: 'Disabled successfully !',
        successfullyEnabled: 'Successfully enabled !',
        departmentexistMsg: 'The department does not exist !',
        personexistMsg: 'The person does not exist !',
        presencesubmenu: 'Presence submenu!',
        menudoesnotexist: 'Menu does not exist!',
        processdoesnotexist: 'The process template does not exist!',
        vendordoesnotexist: 'The vendor does not exist!',
        productdoesnotexist: 'The product does not exist!',
        categorydoesnotexist: 'The category does not exist!',
        modeldoesnotexist: 'The model does not exist!',
        colourdoesnotexist: 'The colour does not exist!',
        purchasedoesnotexist: 'The purchase info does not exist!',
        currencydoesnotexist: 'The currency does not exist!',
        filedoesnotexist: 'The file does not exist!',
        podoesnotexist: 'The po does not exist!',
        poshipmentdoesnotexist: 'The shipment info does not exist!',
    },
}