<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/UserList' }">{{$t('message.vueUser.crumbUserManager')}}</el-breadcrumb-item>
        <el-breadcrumb-item v-if="userForm.id==0">{{$t('message.add')}}</el-breadcrumb-item>
        <el-breadcrumb-item v-else>{{$t('message.edit')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form ref="userForm" :model="userForm" class="demo-userForm"
             :label-position="labelPosition"
             label-width="120px"
             :rules="rules">
                <el-row align="center" :gutter="5">
                    <el-col :span="8">
                <el-form-item :label="$t('message.vueUser.labCompanyName')" prop="company">
                            <el-select v-model="userForm.company" value-key="id" style="width:100%"
                                       :placeholder="$t('message.pleaseSelect')" @change="getDepartment($event)" filterable>
                        <el-option v-for="item in companylist"
                                   :key="item.id"
                                   :label="item.companyName"
                                   :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
                    <el-col :span="8">
                <el-form-item :label="$t('message.vueUser.labDepartmentName')" prop="department">
                            <el-select v-model="userForm.department" value-key="id" style="width:100%"
                                       :placeholder="$t('message.pleaseSelect')" filterable>
                        <el-option v-for="item in departmentlist"
                                   :key="item.id"
                                   :label="item.departmentName"
                                   :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
                <el-row align="center" :gutter="5">
                    <el-col :span="8">
                <el-form-item :label="$t('message.vueUser.labName')" prop="name">
                            <el-input v-model="userForm.name" style="width:100%"></el-input>
                </el-form-item>
            </el-col>
                    <el-col :span="8">
                <el-form-item :label="$t('message.vueUser.labSex')" prop="sex">
                            <el-radio-group v-model="userForm.sex" style="width:100%">
                        <el-radio :label="1">{{$t('message.vueUser.selMan')}}</el-radio>
                        <el-radio :label="2">{{$t('message.vueUser.selWoman')}}</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-col>
        </el-row>
                <el-row align="center" :gutter="5">
                    <el-col :span="8">
                        <el-form-item :label="$t('message.vueUser.labAccount')" prop="account">
                            <el-input v-model="userForm.account" style="width:100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                <el-form-item :label="$t('message.vueUser.labPassword')" prop="password">
                            <el-input v-model="userForm.password" style="width:100%"></el-input>
                </el-form-item>
            </el-col>

        </el-row>
                <el-row align="center" :gutter="5">
                    <el-col :span="8">
                <el-form-item :label="$t('message.vueUser.labRank')" prop="rank">
                            <el-select v-model="userForm.rank" :placeholder="$t('message.pleaseSelect')" style="width:100%">
                        <el-option :label="$t('message.pleaseSelect')" value=''></el-option>
                        <el-option v-for="item in whereRanks"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
                    <el-col :span="8">
                <el-form-item :label="$t('message.vueUser.labGrade')" prop="grade">
                            <el-select v-model="userForm.grade" :placeholder="$t('message.pleaseSelect')" style="width:100%">
                        <el-option :label="$t('message.pleaseSelect')" value=''></el-option>
                        <el-option v-for="item in whereGrades"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>

                <el-row align="center" :gutter="5">
                    <el-col :span="8">
                <el-form-item :label="$t('message.vueUser.labLeader')" prop="leader">
                            <el-input v-model="userForm.leader" @click="examineBtn" readonly style="width:100%"></el-input>
                    <el-input v-model="userForm.leaderNumber" style="display:none"></el-input>
                </el-form-item>
            </el-col>
                    <el-col :span="8">
                <el-form-item :label="$t('message.vueUser.labEmail')" prop="email">
                            <el-input v-model="userForm.email" style="width:100%"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
                <el-row align="center" :gutter="5">
                    <el-col :span="16">
                <el-form-item :label="$t('message.vueUser.labLanguage')" prop="language">
                            <el-select v-model="userForm.language" :placeholder="$t('message.pleaseSelect')" style="width:100%">
                        <el-option v-for="item in wherelanguage"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
                    <el-col>
                <el-form-item>
                    <el-button  class="button_submit" size="mini" @click="submitForm('userForm')"
                               :disabled="disabledSubmit">{{$t('message.submit')}}</el-button>
                    <el-button @click="returnForm()" class="button_return" size="mini">{{$t('message.return')}}</el-button>
                </el-form-item>
            </el-col>
        </el-row>
        <userSelect ref="msgBtn" v-on:getUserEven="getUser"></userSelect>
    </el-form>
</template>
<style>
    .button_return {
        color: #fff;
        background-color: grey;
        font-weight: bolder;
    }
    .button_submit {
        color: #fff;
        background-color: royalblue;
        font-weight: bolder;
    }
</style>
<script>
    import AppStorage from '@/common/storage/app-storage'
    import userSelect from '../../components/User/UserSelect'
    export default {
        name: '',
        components: {
            //挂载组件
            userSelect, //自定义的标签
        },
        data() {
            return {
                disabledSubmit: false,
                companylist: [],
                departmentlist: [],
                wherelanguage: [{
                    value: 'en',
                    label: 'English'
                }, {
                    value: 'zh-tw',
                    label: '繁體中文'
                }],
                whereGrades: [{
                    value: 1,
                    label: this.$t('message.vueUser.whereGrades1')
                }, {
                    value: 2,
                    label: this.$t('message.vueUser.whereGrades2')
                }, {
                    value: 3,
                    label: this.$t('message.vueUser.whereGrades3')
                }, {
                    value: 4,
                    label: this.$t('message.vueUser.whereGrades4')
                }, {
                    value: 5,
                    label: this.$t('message.vueUser.whereGrades5')
                }, {
                    value: 6,
                    label: this.$t('message.vueUser.whereGrades6')
                }, {
                    value: 7,
                    label: this.$t('message.vueUser.whereGrades7')
                }, {
                    value: 8,
                    label: this.$t('message.vueUser.whereGrades8')
                }, {
                    value: 9,
                    label: this.$t('message.vueUser.whereGrades9')
                }, {
                    value: 10,
                    label: this.$t('message.vueUser.whereGrades10')
                }, {
                    value: 11,
                    label: this.$t('message.vueUser.whereGrades11')
                }, {
                    value: 12,
                    label: this.$t('message.vueUser.whereGrades12')
                }, {
                    value: 13,
                    label: this.$t('message.vueUser.whereGrades13')
                }, {
                    value: 14,
                    label: this.$t('message.vueUser.whereGrades14')
                }],
                whereRanks: [{
                    value: 1,
                    label: this.$t('message.vueUser.whereRanks1')
                }, {
                    value: 2,
                    label: this.$t('message.vueUser.whereRanks2')
                }, {
                    value: 3,
                    label: this.$t('message.vueUser.whereRanks3')
                }, {
                    value: 4,
                    label: this.$t('message.vueUser.whereRanks4')
                }, {
                    value: 5,
                    label: this.$t('message.vueUser.whereRanks5')
                }, {
                    value: 6,
                    label: this.$t('message.vueUser.whereRanks6')
                }, {
                    value: 7,
                    label: this.$t('message.vueUser.whereRanks7')
                }, {
                    value: 8,
                    label: this.$t('message.vueUser.whereRanks8')
                    }, {
                        value: 9,
                        label: this.$t('message.vueUser.whereRanks9')
                    }, {
                        value: 10,
                        label: this.$t('message.vueUser.whereRanks10')
                    }, {
                        value: 11,
                        label: this.$t('message.vueUser.whereRanks11')
                    }, {
                        value: 12,
                        label: this.$t('message.vueUser.whereRanks12')
                    }, {
                        value: 13,
                        label: this.$t('message.vueUser.whereRanks13')
                    }, {
                        value: 14,
                        label: this.$t('message.vueUser.whereRanks14')
                    }, {
                        value: 15,
                        label: this.$t('message.vueUser.whereRanks15')
                    }, {
                        value: 16,
                        label: this.$t('message.vueUser.whereRanks16')
                    }, {
                        value: 17,
                        label: this.$t('message.vueUser.whereRanks17')
                    }, {
                        value: 18,
                        label: this.$t('message.vueUser.whereRanks18')
                    }, {
                        value: 19,
                        label: this.$t('message.vueUser.whereRanks19')
                    }, {
                        value: 20,
                        label: this.$t('message.vueUser.whereRanks20')
                    }, {
                        value: 21,
                        label: this.$t('message.vueUser.whereRanks21')
                    }],
                userForm: {
                    id: 0,
                    company: null,
                    department: null,
                    sex: 1,
                    name: '',
                    account:'',
                    rank: '',
                    grade:'',
                    email: '',
                    //address: '',
                    language: '',
                    number: '',
                    password: '',
                    //tel: '',
                    leader: '',
                    leaderNumber: '',
                    userNumber: AppStorage.getNumber()
                },
                seen: true,
            }
        },
        computed: {
            rules() {
                const rules = {
                    company: [
                        { required: true, message: this.$t('message.vueUser.verifyCompanyRequired'), trigger: 'change', type: 'object' }
                    ],
                    department: [
                        { required: true, message: this.$t('message.vueUser.verifyDepartmentRequired'), trigger: 'change', type: 'object' }
                    ],
                    language: [
                        { required: true, message: this.$t('message.vueUser.verifyLanguageRequired'), trigger: 'blur' }
                    ],
                    password: [
                        { required: true, message: this.$t('message.vueUser.verifyPasswordRequired'), trigger: 'blur' },
                        { min: 6, max: 20, message: this.$t('message.vueUser.verifyPasswordLen') , trigger: 'blur' }
                    ],
                    account: [
                        { required: true, message: this.$t('message.vueUser.verifyAccountRequired'), trigger: 'blur' }
                    ],
                    name: [
                        { required: true, message: this.$t('message.vueUser.verifyNameRequired'), trigger: 'blur' }
                    ],
                    email: [
                        { required: false, message: this.$t('message.vueUser.verifyEmail'), trigger: 'blur', type: 'email'  }
                    ]
                };
                return rules;
            }
        },
        methods: {
            getCompany() {
                this.axios
                    .get('/api/company/getcompanylist', {
                        params: {
                            isFactory: 0,
                            userNumber: AppStorage.getNumber(),
                            isAll: true
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            this.companylist = infos;
                        }
                    })
            },
            getDepartment(opt) {
                this.departmentlist = [];
                this.userForm.department = null;
                if (opt.id != "") {
                    this.axios
                        .get('/api/department/getdepartmentlist', {
                            params: {
                                companyNumber: opt.companyNumber,
                                userNumber: AppStorage.getNumber(),
                                isAll: false
                            }
                        }).then(response => {
                            if (response.data.code == 1) {
                                var infos = response.data.data;
                                this.departmentlist = infos;
                            }
                        })
                }
            },
            getInfo() {
                var infoId = this.$route.query.id;
                if (infoId === '0') {
                    return;
                }
                this.axios
                    .get('/api/user/getuserbyid', {
                        params: {
                            Id: infoId,
                        }
                    }) .then(response => {
                        var info = response.data.data;
                        if (response.data.code == 1 && info != null) {
                            this.userForm.id = info.id;
                            this.userForm.company = {
                                id: info.companyId,
                                companyName: info.companyName,
                                companyNumber: info.companyNumber
                            };
                            this.getDepartment(this.userForm.company);
                            this.userForm.department = {
                                id: info.departmentId,
                                departmentName: info.departmentName,
                                departmentNumber: info.departmentNumber,
                            };
                            this.userForm.number = info.number;
                            this.userForm.name = info.name;
                            this.userForm.account = info.account;
                            this.userForm.password = info.password;
                            //this.userForm.tel = info.tel;
                            //this.userForm.address = info.address;
                            this.userForm.language = info.language;
                            this.userForm.sex = info.sex;
                            this.userForm.email = info.email;
                            this.userForm.rank = info.rank == null ? '' : info.rank;
                            this.userForm.grade = info.grade == null ? '' : info.grade;
                            this.userForm.leader = info.leader;
                            this.userForm.leaderNumber = info.leaderNumber;
                            this.seen = false;
                        }
                    });
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.disabledSubmit = true;
                        this.axios
                            .post('/api/user/addoredituser', {
                                Id: this.userForm.id,
                                CompanyId: this.userForm.company.id,
                                CompanyNumber: this.userForm.company.companyNumber,
                                DepartmentId: this.userForm.department.id,
                                DepartmentNumber: this.userForm.department.departmentNumber,
                                Password: this.userForm.password,
                                Name: this.userForm.name,
                                Account: this.userForm.account,
                                //Tel:this.userForm.tel ,
                                //Address:this.userForm.address ,
                                Language:this.userForm.language,
                                Sex:this.userForm.sex,
                                Email: this.userForm.email,
                                LeaderNumber: this.userForm.leaderNumber,
                                Rank: this.userForm.rank == '' ? null : this.userForm.rank,
                                Grade: this.userForm.grade == '' ? null : this.userForm.grade,
                                UserNumber: this.userForm.userNumber
                            })
                            .then(response => {
                                if (response.data.code != 1) {
                                    if (response.data.msg == '存在账号') {
                                        this.$message.error(this.$t('message.vueUser.msgExistAccount'));
                                    } else {
                                        this.$message.error(this.$t('message.errer'));
                                    }
                                } else {
                                    this.$message.success(this.$t('message.success'));
                                    this.$router.push("/UserList");
                                }
                                this.disabledSubmit = false;
                            }).catch(error => {
                                console.log(error);
                                this.$message.error(this.$t('message.messageBox.connecterror'));
                                this.disabledSubmit = false;
                            });

                    } else {
                        return false;
                    }
                });
            },
            returnForm() {
                this.$router.go(-1);
            },
            examineBtn() {
                this.$refs.msgBtn.init();
            },
            getUser(user) {
                if (user.length > 0) {
                    this.userForm.leader = user[0].name;
                    this.userForm.leaderNumber = user[0].number;
                } else {
                    this.userForm.leader = "";
                    this.userForm.leaderNumber = "";
                }
            },
        },
        mounted() {
            this.getCompany();
            this.getInfo();
        },
    }

</script>